var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import React, { useState, useEffect, useContext, useCallback } from 'react';
import Decimal from 'decimal.js';
import axios from 'axios';
import { Button, Dialog, TextField, DialogTitle, DialogContent, DialogActions, CircularProgress, Box, Autocomplete, Alert, } from '@mui/material';
import buildUrl from 'build-url';
import { connect, useDispatch } from 'react-redux';
import FeeService from '../../../../../services/feeService';
import Messages from '../../../../../shared/helpers/errorMessages';
import { RatesContext } from '../../../../../providers/RatesProvider';
import showNotification from '../../../../../shared/helpers/notifications';
import { composeErrorMessage } from '../../../../../shared/helpers/interceptors';
import { ModalSpinnerContainer, ModalLoadingContainer } from '../../styling/style';
import { CLOSE_ERROR_NOTICE } from '../../../../../redux/actionTypes/apiErrorsActionTypes';
import WithdrawalsAddressesService from '../../../../../services/withdrawalsAddressesService';
import { getCustodyCurrencies } from '../../../../../redux/selectors/currenciesSelectors';
import { getEnvSettings } from '../../../../../config/environmentSettings';
import { LoadingButton } from '@mui/lab';
const feeLevelArr = [
    { label: 'low', value: 'low' },
    { label: 'high', value: 'high' },
    { label: 'medium', value: 'medium' },
    { label: 'custom', value: 'custom' },
];
const WithdrawalModal = ({ open, handleSendWithdraw, toggleFunc, loading, currencies, selectedClient, userCode, }) => {
    const [amount, setAmount] = useState('');
    const [precision, setPrecision] = useState(0);
    const [disabled, setDisabled] = useState(true);
    const [customFee, setCustomFee] = useState('');
    const [referenceId, setReferenceId] = useState('');
    const [loadingSpinner, setLoadingSpinner] = useState(false);
    const [wallets, setWallets] = useState([]);
    const [allWallets, setAllWallets] = useState([]);
    const [loadingAddresses, setLoadingAddresses] = useState(false);
    const [allAddresses, setAllAddresses] = useState([]);
    const [walletsOptions, setWalletsOptions] = useState([]);
    const [feeLevel, setFeeLevel] = useState(null);
    const [addressesOptions, setAddressesOptions] = useState([]);
    const [selectedWallet, setSelectedWallet] = useState(null);
    const [selectedAddress, setSelectedAddress] = useState(null);
    const [suggestedFees, setSuggestedFees] = useState(null);
    const [feeLevelArrToRender, setFeeLevelArrToRender] = useState([]);
    const [currency, setCurrency] = useState(currencies.length ? currencies[0] : null);
    const [addressApprove, setAddressApprove] = useState(false);
    const [isApproved, setIsApproved] = useState(false);
    const [payload, setPayload] = useState();
    const [notabeneInstance, setNotabeneInstance] = useState();
    const [needsApproval, setNeedsApproval] = useState(false);
    const [txDetails, setTxDetails] = useState();
    const [widgetError, setWidgetError] = useState(false);
    const [showWidgetError, setShowWidgetError] = useState(false);
    const ctx = useContext(RatesContext);
    const dispatch = useDispatch();
    const errorNotice = useCallback(() => dispatch({ type: CLOSE_ERROR_NOTICE }), [dispatch]);
    const fixLabel = (label) => {
        const parts = label.split('; ');
        let result = '';
        if (parts.length === 0) {
            return label;
        }
        parts.forEach((p, i) => {
            if (i === 0) {
                result += `${p.toUpperCase()} - `;
            }
            else if (i === 1) {
                result += `(${p.trim()}) `;
            }
            else if (i === 2) {
                result += `Total: ${p} `;
            }
            else if (p) {
                result += `(Available: ${p})`;
            }
        });
        return result;
    };
    const fetchWallets = () => {
        const clientCode = selectedClient ? selectedClient.value : userCode;
        if (!currency || !clientCode)
            return;
        axios
            .get(`custody/${clientCode}/wallets?state=all&currency_code=${currency.code}&limit=1000`)
            .then((result) => {
            setAllWallets(result.data.records);
        })
            .catch(() => {
            showNotification({
                message: 'Failed fetching wallets for withdrawal',
                color: 'error',
            });
        });
    };
    const checkAddress = (client_code, wallet_address, currency_code) => {
        return axios.post('/travelrule/whitelist/details', { client_code, wallet_address, currency_code });
    };
    const initApprovalWidget = () => {
        function addScript(src) {
            const s = document.createElement('script');
            s.setAttribute('src', src);
            s.setAttribute('id', 'nb-script');
            document.body.appendChild(s);
            return s;
        }
        return new Promise((resolve, reject) => {
            try {
                const scriptElement = document.getElementById('nb-script');
                const script = scriptElement
                    ? scriptElement
                    : addScript('https://unpkg.com/@notabene/javascript-sdk@1.20.0/dist/es/index.js');
                const getWidgetConfig = () => __awaiter(void 0, void 0, void 0, function* () {
                    setWidgetError(false);
                    return axios
                        .get('/tr/authToken', { baseURL: getEnvSettings().frontendApiUrl })
                        .then((response) => response.data)
                        .catch((e) => {
                        setWidgetError(true);
                        const message = composeErrorMessage(e, 'Failed to load Notabene auth token');
                        showNotification({
                            message,
                            color: 'error',
                            dispatch: errorNotice,
                        });
                    });
                });
                const init = () => __awaiter(void 0, void 0, void 0, function* () {
                    const widgetConfig = yield getWidgetConfig();
                    console.log(widgetConfig);
                    if (!widgetConfig) {
                        return;
                    }
                    const w = new window.Notabene({
                        widget: widgetConfig.widget_url,
                        vaspDID: widgetConfig.vaspdid,
                        container: '#nb-withdrawal',
                        authToken: widgetConfig.token,
                        onValidStateChange: (isValid) => {
                            if (isValid) {
                                setIsApproved(true);
                                setTxDetails(w.tx);
                            }
                        },
                        transactionTypeAllowed: 'ALL',
                        nonCustodialDeclarationType: 'SIGNATURE',
                        fieldsProps: {
                            geographicAddress: {
                                forceDisplay: true,
                                optional: false,
                            },
                            dateAndPlaceOfBirth: {
                                forceDisplay: true,
                                optional: false,
                            },
                            nationalIdentification: {
                                forceDisplay: true,
                            },
                        },
                        dictionary: {
                            'Place of Birth': 'Country of Birth',
                            'Enter place of birth': 'Enter country of birth',
                        },
                    });
                    setNotabeneInstance(w);
                });
                if (scriptElement) {
                    init();
                }
                script.onload = () => {
                    init();
                    resolve(true);
                };
            }
            catch (error) {
                alert(error);
                reject(false);
            }
        });
    };
    const handleCreateWithdrawal = () => {
        destroyWidget();
        setAddressApprove(false);
        setIsApproved(false);
        if (payload) {
            handleSendWithdraw(payload, createTravelRule());
        }
    };
    const destroyWidget = () => {
        if (notabeneInstance) {
            notabeneInstance.destroyWidget();
        }
    };
    const createTravelRule = () => {
        const clientCode = (selectedClient === null || selectedClient === void 0 ? void 0 : selectedClient.value) || userCode;
        if (!selectedAddress || !txDetails || !clientCode) {
            return undefined;
        }
        let payload = undefined;
        const address = selectedAddress.memo
            ? selectedAddress === null || selectedAddress === void 0 ? void 0 : selectedAddress.address.concat(`:${selectedAddress === null || selectedAddress === void 0 ? void 0 : selectedAddress.memo}`)
            : selectedAddress === null || selectedAddress === void 0 ? void 0 : selectedAddress.address;
        // If address belongs to the customer and is custodial
        if (txDetails.originatorEqualsBeneficiary && !txDetails.isNonCustodial) {
            payload = {
                client_code: clientCode,
                wallet_address: address,
                currency_code: selectedAddress === null || selectedAddress === void 0 ? void 0 : selectedAddress.currency,
                is_non_custodial: txDetails.isNonCustodial,
                vasp_id: txDetails.beneficiaryVASPdid,
                customer_owns_address: true,
                proof_type: 'DECLARATION',
                proof_value: 'checkbox',
            };
        }
        // If address belongs to the customer and is non-custodial
        if (txDetails.originatorEqualsBeneficiary && txDetails.isNonCustodial) {
            payload = {
                client_code: clientCode,
                wallet_address: address,
                currency_code: selectedAddress === null || selectedAddress === void 0 ? void 0 : selectedAddress.currency,
                is_non_custodial: txDetails.isNonCustodial,
                vasp_id: txDetails.beneficiaryVASPdid,
                proof_type: txDetails.beneficiaryProof.type === 'checkbox' ? 'DECLARATION' : 'SIGNATURE',
                proof_value: txDetails.beneficiaryProof.proof,
                customer_owns_address: true,
                wallet_provider: txDetails.beneficiaryProof.wallet_provider,
            };
        }
        // If address doesn't belong to the customer and is custodial
        if (!txDetails.originatorEqualsBeneficiary &&
            !txDetails.isNonCustodial &&
            txDetails.beneficiaryPersonType === 'NATURAL') {
            payload = {
                client_code: clientCode,
                wallet_address: address,
                currency_code: selectedAddress === null || selectedAddress === void 0 ? void 0 : selectedAddress.currency,
                is_non_custodial: txDetails.isNonCustodial,
                vasp_id: txDetails.beneficiaryVASPdid,
                natural_first_name: txDetails.ivms.beneficiary.beneficiaryPersons[0].naturalPerson.name[0].nameIdentifier[0].secondaryIdentifier,
                natural_last_name: txDetails.ivms.beneficiary.beneficiaryPersons[0].naturalPerson.name[0].nameIdentifier[0].primaryIdentifier,
                natural_pob: txDetails.ivms.beneficiary.beneficiaryPersons[0].naturalPerson.dateAndPlaceOfBirth.placeOfBirth,
                natural_dob: txDetails.ivms.beneficiary.beneficiaryPersons[0].naturalPerson.dateAndPlaceOfBirth.dateOfBirth,
                natural_national_identifier_type: txDetails.beneficiaryNationalIdentification.nationalIdentifierType,
                natural_national_identifier_value: txDetails.beneficiaryNationalIdentification.nationalIdentifier,
                natural_national_identifier_country: txDetails.beneficiaryNationalIdentification.countryOfIssue,
                natural_geographic_address: txDetails.beneficiaryGeographicAddress,
                customer_owns_address: false,
                person_type: 'NATURAL',
            };
        }
        // If address doesn't belong to the customer (beneficiary is natural person) and is non-custodial
        if (!txDetails.originatorEqualsBeneficiary &&
            txDetails.isNonCustodial &&
            txDetails.beneficiaryPersonType === 'NATURAL') {
            payload = {
                client_code: clientCode,
                wallet_address: address,
                currency_code: selectedAddress === null || selectedAddress === void 0 ? void 0 : selectedAddress.currency,
                is_non_custodial: txDetails.isNonCustodial,
                proof_value: txDetails.beneficiaryProof.proof,
                proof_type: txDetails.beneficiaryProof.type,
                natural_first_name: txDetails.ivms.beneficiary.beneficiaryPersons[0].naturalPerson.name[0].nameIdentifier[0].secondaryIdentifier,
                natural_last_name: txDetails.ivms.beneficiary.beneficiaryPersons[0].naturalPerson.name[0].nameIdentifier[0].primaryIdentifier,
                natural_pob: txDetails.ivms.beneficiary.beneficiaryPersons[0].naturalPerson.dateAndPlaceOfBirth.placeOfBirth,
                natural_dob: txDetails.ivms.beneficiary.beneficiaryPersons[0].naturalPerson.dateAndPlaceOfBirth.dateOfBirth,
                natural_national_identifier_type: txDetails.beneficiaryNationalIdentification.nationalIdentifierType,
                natural_national_identifier_value: txDetails.beneficiaryNationalIdentification.nationalIdentifier,
                natural_national_identifier_country: txDetails.beneficiaryNationalIdentification.countryOfIssue,
                natural_geographic_address: txDetails.beneficiaryGeographicAddress,
                customer_owns_address: false,
                person_type: 'NATURAL',
            };
        }
        // If address doesn't belong to the customer (beneficiary is legal person/company) and is custodial
        if (!txDetails.originatorEqualsBeneficiary &&
            !txDetails.isNonCustodial &&
            txDetails.beneficiaryPersonType === 'LEGAL') {
            payload = {
                client_code: clientCode,
                wallet_address: address,
                currency_code: selectedAddress === null || selectedAddress === void 0 ? void 0 : selectedAddress.currency,
                is_non_custodial: txDetails.isNonCustodial,
                vasp_id: txDetails.beneficiaryVASPdid,
                legal_name: txDetails.beneficiaryName,
                legal_national_identifier_type: txDetails.beneficiaryNationalIdentification.nationalIdentifierType,
                legal_national_identifier_value: txDetails.beneficiaryNationalIdentification.nationalIdentifier,
                legal_national_identifier_country: txDetails.beneficiaryNationalIdentification.countryOfIssue,
                legal_geographic_address: txDetails.beneficiaryGeographicAddress,
                customer_owns_address: false,
                person_type: 'LEGAL',
            };
        }
        // If address doesn't belong to the customer (beneficiary is legal person/company) and is non-custodial
        if (!txDetails.originatorEqualsBeneficiary &&
            txDetails.isNonCustodial &&
            txDetails.beneficiaryPersonType === 'LEGAL') {
            payload = {
                client_code: clientCode,
                wallet_address: address,
                currency_code: selectedAddress === null || selectedAddress === void 0 ? void 0 : selectedAddress.currency,
                is_non_custodial: txDetails.isNonCustodial,
                proof_value: txDetails.beneficiaryProof.proof,
                proof_type: txDetails.beneficiaryProof.type,
                legal_name: txDetails.beneficiaryName,
                legal_national_identifier_type: txDetails.beneficiaryNationalIdentification.nationalIdentifierType,
                legal_national_identifier_value: txDetails.beneficiaryNationalIdentification.nationalIdentifier,
                legal_national_identifier_country: txDetails.beneficiaryNationalIdentification.countryOfIssue,
                legal_geographic_address: txDetails.beneficiaryGeographicAddress,
                customer_owns_address: false,
                person_type: 'LEGAL',
            };
        }
        if (!payload) {
            return;
        }
        return payload;
    };
    const fetchAddresses = (currencyCode, client) => {
        const urlOptions = {
            queryParams: {
                limit: '1000',
                state: 'approved',
                sort_by: 'created',
                sort_direction: 'desc',
                currency_code: currencyCode,
            },
            path: '/custody/withdrawals/addresses',
        };
        if (client) {
            urlOptions.queryParams.clientCode = client.value;
        }
        const endpointUrl = buildUrl('', urlOptions);
        const depositAddressesService = new WithdrawalsAddressesService({
            url: endpointUrl,
            method: 'get',
        });
        setLoadingAddresses(true);
        depositAddressesService
            .makeRequest()
            .then((response) => {
            setLoadingAddresses(false);
            const options = response.records.map((r) => ({
                label: `${r.label}; ${r.address}`,
                value: r.code,
                currency: r.currency,
                wallet_code: r.wallet_code,
                address: r.address,
                customer_code: r.customer_code,
                memo: r.memo,
            }));
            setAllAddresses(options);
        })
            .catch((e) => {
            setLoadingAddresses(false);
            const message = composeErrorMessage(e, Messages.WITHDRAWAL_ADDRESSES_FETCH);
            showNotification({
                message,
                color: 'error',
                dispatch: errorNotice,
            });
        });
    };
    const fetchSuggestedFees = (currencyCode) => {
        const urlOptions = {
            queryParams: {
                currency_code: currencyCode,
            },
            path: '/custody/fees',
        };
        const endpointUrl = buildUrl('', urlOptions);
        const service = new FeeService({
            url: endpointUrl,
            method: 'get',
        });
        service
            .makeRequest()
            .then((response) => {
            setSuggestedFees(response.fees);
        })
            .catch((e) => {
            setSuggestedFees(null);
            setFeeLevelArrToRender(feeLevelArr);
            setFeeLevel(feeLevelArr[0]);
            const message = composeErrorMessage(e, Messages.CUSTODY_FEES_FETCH);
            showNotification({
                message,
                color: 'error',
                dispatch: errorNotice,
            });
        });
    };
    const handleInitiateTransaction = () => __awaiter(void 0, void 0, void 0, function* () {
        try {
            const isFeeGreaterThanOne = customFee ? new Decimal(customFee).greaterThan(1) : false;
            const fee = isFeeGreaterThanOne
                ? Decimal.mul(customFee, Decimal.pow(10, Decimal.mul(-1, precision))).toFixed()
                : customFee;
            const payload = {
                amount,
                reference_id: referenceId,
                custom_fee_size: `${fee}`,
                currency: (currency === null || currency === void 0 ? void 0 : currency.code) || '',
                fee_level: (feeLevel === null || feeLevel === void 0 ? void 0 : feeLevel.value) || '',
                wallet_code: (selectedWallet === null || selectedWallet === void 0 ? void 0 : selectedWallet.value) || '',
                address_code: (selectedAddress === null || selectedAddress === void 0 ? void 0 : selectedAddress.value) || '',
            };
            setPayload(payload);
            if (!needsApproval) {
                handleSendWithdraw(payload);
                return;
            }
            setAddressApprove(true);
            if (!currency) {
                return;
            }
            const convertedAmount = new Decimal(amount).times(new Decimal(10).pow(currency === null || currency === void 0 ? void 0 : currency.decimals)).toFixed();
            if (notabeneInstance && notabeneInstance !== undefined && selectedAddress) {
                const address = selectedAddress.memo
                    ? selectedAddress === null || selectedAddress === void 0 ? void 0 : selectedAddress.address.concat(`:${selectedAddress === null || selectedAddress === void 0 ? void 0 : selectedAddress.memo}`)
                    : selectedAddress === null || selectedAddress === void 0 ? void 0 : selectedAddress.address;
                notabeneInstance.setTransaction({
                    transactionAsset: (currency === null || currency === void 0 ? void 0 : currency.asset_type) || currency.code.toUpperCase().replace('_', '-'),
                    beneficiaryAccountNumber: address,
                    transactionAmount: convertedAmount,
                });
                notabeneInstance.renderWidget();
            }
        }
        catch (e) {
            showNotification({
                message: `Something went wrong, error: ${e}`,
                color: 'error',
                dispatch: errorNotice,
            });
        }
    });
    const onAmountChange = (evt) => {
        const val = evt.target.value.trim();
        if (isNaN(Number(val))) {
            return;
        }
        setAmount(val);
    };
    const handleReferenceChange = (evt) => {
        const regExp = /^[a-zA-Z0-9_\-]*$/;
        const { value } = evt.target;
        if (regExp.test(value)) {
            setReferenceId(value);
        }
    };
    const handleCustomFeeChange = (evt) => {
        const val = evt.target.value.trim();
        if (isNaN(Number(val))) {
            return;
        }
        setCustomFee(val);
    };
    const handleCurrencyChange = (event, newValue) => {
        setCurrency(newValue);
        setSuggestedFees(null);
        setShowWidgetError(false);
    };
    const handleLevelChange = (event, newValue) => {
        setFeeLevel(newValue);
    };
    const handleSelectAddress = (event, newValue) => {
        setShowWidgetError(false);
        const clientCode = (selectedClient === null || selectedClient === void 0 ? void 0 : selectedClient.value) || userCode;
        const address = (newValue === null || newValue === void 0 ? void 0 : newValue.memo) ? newValue === null || newValue === void 0 ? void 0 : newValue.address.concat(`:${newValue === null || newValue === void 0 ? void 0 : newValue.memo}`) : newValue === null || newValue === void 0 ? void 0 : newValue.address;
        if (newValue && clientCode && address) {
            checkAddress(clientCode, address, newValue.currency)
                .then(() => {
                setNeedsApproval(false);
            })
                .catch(() => {
                if (widgetError) {
                    setShowWidgetError(true);
                }
                setNeedsApproval(true);
            })
                .finally(() => {
                setSelectedAddress(newValue);
            });
        }
    };
    const handleSelectWallet = (event, newValue) => {
        setSelectedWallet(newValue);
    };
    useEffect(() => {
        setFeeLevel(null);
        if (currency) {
            fetchSuggestedFees(currency.code);
            fetchWallets();
        }
        setSelectedAddress(null);
        const currencyItem = currency;
        if (currencyItem) {
            setPrecision(currencyItem.decimals);
        }
        else {
            showNotification({
                message: 'Currency not configured',
                color: 'error',
                dispatch: errorNotice,
            });
        }
    }, [currency]);
    useEffect(() => {
        if (currency) {
            fetchAddresses(currency.code, selectedClient);
        }
    }, [currency, selectedClient]);
    useEffect(() => {
        if (suggestedFees && currency && currency.fee_value) {
            const filtered = feeLevelArr.map((f) => {
                if (f.label === 'custom')
                    return f;
                const feeValue = suggestedFees[f.label];
                const currentPrice = ctx.service && ctx.service.getCurrentPrice(currency.code.toLowerCase());
                const priceValue = currentPrice === 'Not convertible' || !currentPrice ? '0' : currentPrice;
                const curencyFee = Decimal.mul(feeValue, currency.fee_value).toFixed();
                let fee;
                if ((currency === null || currency === void 0 ? void 0 : currency.parent_code) === '') {
                    fee = Decimal.mul(curencyFee, priceValue);
                }
                else {
                    const parentPrice = ctx.service && ctx.service.getCurrentPrice(currency.parent_code);
                    const priceValue = parentPrice === 'Not convertible' || !parentPrice ? '0' : parentPrice;
                    fee = Decimal.mul(curencyFee, priceValue);
                }
                return Object.assign({}, f, {
                    label: `${f.label} (${curencyFee} ${currency.parent_code.toUpperCase() || currency.code.toUpperCase()} ≈ ${fee} USD)`,
                });
            });
            setFeeLevelArrToRender(feeLevelArr);
            if (filtered.length > 0) {
                setFeeLevel(feeLevelArr[0]);
            }
        }
    }, [suggestedFees, currency]);
    useEffect(() => {
        const options = allWallets
            .filter((w) => w.state === 'active' && ['regular', 'manual', 'pool'].includes(w.type))
            .map((w) => {
            var _a, _b, _c, _d, _e;
            const activeAddress = w.compliance_addresses.find((a) => a.state === 'active');
            const address = (activeAddress === null || activeAddress === void 0 ? void 0 : activeAddress.address) || '';
            const legacyAddress = (activeAddress === null || activeAddress === void 0 ? void 0 : activeAddress.legacy_address) || '';
            const addressData = legacyAddress !== '' ? address.concat(', Legacy: ').concat(legacyAddress) : address;
            const balance = ((_a = w.balance) === null || _a === void 0 ? void 0 : _a.available) !== ((_b = w.balance) === null || _b === void 0 ? void 0 : _b.total)
                ? `${((_c = w.balance) === null || _c === void 0 ? void 0 : _c.total) || ''}; ${((_d = w.balance) === null || _d === void 0 ? void 0 : _d.available) || ''}`
                : ((_e = w.balance) === null || _e === void 0 ? void 0 : _e.total) || '';
            return {
                value: w.code,
                currency: w.currency_code || '',
                label: `${w.label}; ${addressData}; ${balance}`,
                address,
                customer_code: w.customer_code,
            };
        });
        setWallets(options);
    }, [allWallets]);
    useEffect(() => {
        setLoadingSpinner(true);
        const filteredWallets = wallets
            .filter((w) => w.currency.toLowerCase() === ((currency === null || currency === void 0 ? void 0 : currency.code) || '').toLowerCase())
            .map((w) => (Object.assign(Object.assign({}, w), { label: fixLabel(w.label) })));
        setWalletsOptions(filteredWallets);
        setSelectedWallet(null);
        setLoadingSpinner(false);
    }, [currency, wallets]);
    useEffect(() => {
        if (selectedWallet) {
            const filtered = allAddresses
                .filter((a) => a.wallet_code === null || a.wallet_code === selectedWallet.value)
                .map((a) => (Object.assign(Object.assign({}, a), { label: fixLabel(a.label) })));
            setAddressesOptions(filtered);
        }
    }, [selectedWallet, allAddresses]);
    useEffect(() => {
        if (selectedWallet !== null &&
            amount !== '' &&
            feeLevel !== null &&
            selectedAddress !== null &&
            currency !== null) {
            setDisabled(false);
        }
        else {
            setDisabled(true);
        }
    }, [selectedWallet, amount, feeLevel, selectedAddress, currency]);
    useEffect(() => {
        initApprovalWidget().then();
    }, []);
    useEffect(() => {
        if (!widgetError) {
            setShowWidgetError(false);
        }
    }, [widgetError]);
    if (loadingSpinner) {
        return (React.createElement(ModalLoadingContainer, null,
            React.createElement(ModalSpinnerContainer, null,
                React.createElement(CircularProgress, null))));
    }
    return (React.createElement(Dialog, { open: open, onClose: () => {
            toggleFunc();
            if (notabeneInstance && notabeneInstance !== undefined) {
                notabeneInstance.destroyWidget();
            }
        }, maxWidth: 'sm', fullWidth: true },
        addressApprove ? (React.createElement(DialogContent, { sx: { minHeight: '300px' } },
            React.createElement(Box, { id: 'nb-withdrawal', sx: { display: 'flex', justifyContent: 'center', position: 'relative' } }))) : (React.createElement(React.Fragment, null,
            React.createElement(DialogTitle, { id: 'form-dialog-title' }, "Create new Withdrawal"),
            React.createElement(DialogContent, null,
                React.createElement(Autocomplete, { id: 'currency', size: 'small', value: currency, options: currencies, loading: loadingAddresses, placeholder: 'Select currency', style: { marginTop: '10px' }, onChange: handleCurrencyChange, className: 'mui_autocomplete_wrap', getOptionLabel: (option) => `${option.code.toUpperCase()} - ${option.label}`, renderInput: (params) => React.createElement(TextField, Object.assign({}, params, { label: 'Currency', variant: 'outlined' })) }),
                React.createElement(Autocomplete, { id: 'wallet', size: 'small', value: selectedWallet, options: walletsOptions, placeholder: 'Select wallet', style: { marginTop: '10px' }, onChange: handleSelectWallet, className: 'mui_autocomplete_wrap', getOptionLabel: (option) => option.label, renderInput: (params) => React.createElement(TextField, Object.assign({}, params, { label: 'Wallet', variant: 'outlined' })) }),
                React.createElement(TextField, { fullWidth: true, size: 'small', name: 'amount', value: amount, label: 'Amount', margin: 'normal', variant: 'outlined', onChange: onAmountChange, InputLabelProps: { shrink: true } }),
                React.createElement(Autocomplete, { size: 'small', id: 'destination', value: selectedAddress, options: addressesOptions, disabled: !selectedWallet, placeholder: 'Select address', style: { marginTop: '10px' }, onChange: handleSelectAddress, className: 'mui_autocomplete_wrap', getOptionLabel: (option) => option.label, renderOption: (props, option) => (React.createElement(Box, Object.assign({ component: 'li' }, props, { key: option.value }), option.label)), renderInput: (params) => (React.createElement(TextField, Object.assign({}, params, { label: 'Destination', variant: 'outlined', error: showWidgetError }))) }),
                showWidgetError ? (React.createElement(Alert, { color: 'warning', severity: 'error' }, "This address can not be used at the moment due to the technical problems")) : null,
                React.createElement(Autocomplete, { size: 'small', id: 'feeLevel', value: feeLevel, onChange: handleLevelChange, options: feeLevelArrToRender, style: { marginTop: '10px' }, className: 'mui_autocomplete_wrap', getOptionLabel: (option) => option.label, renderInput: (params) => React.createElement(TextField, Object.assign({}, params, { label: 'Blockchain Fee level', variant: 'outlined' })) }),
                (feeLevel === null || feeLevel === void 0 ? void 0 : feeLevel.value) === 'custom' ? (React.createElement(TextField, { fullWidth: true, size: 'small', margin: 'normal', name: 'customFee', value: customFee, label: 'Custom Fee', variant: 'outlined', onChange: handleCustomFeeChange, InputLabelProps: { shrink: true } })) : null,
                React.createElement(TextField, { fullWidth: true, size: 'small', margin: 'normal', variant: 'outlined', name: 'referenceId', value: referenceId, label: 'Reference Id', onChange: handleReferenceChange, InputLabelProps: { shrink: true } })))),
        React.createElement(DialogActions, null,
            React.createElement(Button, { sx: { position: 'initial' }, color: 'primary', variant: 'contained', disabled: disabled || isApproved || addressApprove || showWidgetError, onClick: handleInitiateTransaction }, "Create"),
            React.createElement(LoadingButton, { loading: loading, color: 'primary', variant: 'contained', disabled: !isApproved, onClick: handleCreateWithdrawal, sx: { position: 'initial' } }, "Send"),
            React.createElement(Button, { sx: { position: 'initial' }, variant: 'outlined', onClick: () => {
                    toggleFunc();
                    destroyWidget();
                } }, "Cancel"))));
};
const mapStateToProps = (state) => {
    var _a;
    return ({
        currencies: getCustodyCurrencies(state),
        userCode: (_a = state.client.clientInfo) === null || _a === void 0 ? void 0 : _a.code,
    });
};
export default connect(mapStateToProps)(WithdrawalModal);
